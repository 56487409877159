/**
 *  HeaderComponent.vue
**/
/* template import */
<template src="./app-header.html"></template>
/* style import */
<style lang="scss" src="./app-header.scss"></style>
<script>
export default {
  name: 'AppHeader'
}
</script>
