/**
 *  State module file
**/
import { BASEMAP, API_VERSIONS } from '@deck.gl/carto'

export const initialViewState = () => ({
  longitude: -40,
  latitude: 0,
  zoom: 3,
// This doesn't seem to be the place to put it.
  // projection: {
  //   name: 'equalEarth'
  // }  
})

export const state = {
  credentials: {
    apiBaseUrl: 'https://gcp-us-east1.api.carto.com',
    apiVersion: API_VERSIONS.V3,
// This has to be generated. It can be persistent .
    // accessToken: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImRVNGNZTHAwaThjYnVMNkd0LTE0diJ9.eyJodHRwOi8vYXBwLmNhcnRvLmNvbS9hY2NvdW50X2lkIjoiYWNfaGFkNnJobGgiLCJodHRwOi8vYXBwLmNhcnRvLmNvbS9hY3RpbmdfYXMiOiJnb29nbGUtb2F1dGgyfDExMTI0MTE3NTk3MjE3NDIyNDA3MyIsImlzcyI6Imh0dHBzOi8vYXV0aC5jYXJ0by5jb20vIiwic3ViIjoiRTQ5a2VEZG0yckNyRTc0OGU2U01sVHdhTFVaeG5PV3FAY2xpZW50cyIsImF1ZCI6ImNhcnRvLWNsb3VkLW5hdGl2ZS1hcGkiLCJpYXQiOjE2NjM2ODA3OTksImV4cCI6MTY2Mzc2NzE5OSwiYXpwIjoiRTQ5a2VEZG0yckNyRTc0OGU2U01sVHdhTFVaeG5PV3EiLCJzY29wZSI6InJlYWQ6dG9rZW5zIHdyaXRlOnRva2VucyByZWFkOmltcG9ydHMgd3JpdGU6aW1wb3J0cyByZWFkOmNvbm5lY3Rpb25zIHdyaXRlOmNvbm5lY3Rpb25zIiwiZ3R5IjoiY2xpZW50LWNyZWRlbnRpYWxzIiwicGVybWlzc2lvbnMiOlsicmVhZDp0b2tlbnMiLCJ3cml0ZTp0b2tlbnMiLCJyZWFkOmltcG9ydHMiLCJ3cml0ZTppbXBvcnRzIiwicmVhZDpjb25uZWN0aW9ucyIsIndyaXRlOmNvbm5lY3Rpb25zIl19.mJbLJixPw1A81l1Ip9DXsjl_DAmv_rgHl3n5BCmbq-zvGgY6qsbW4BGF7xv0P_feDkCtKre_OMafXBHe5G3XeTfxsLOYI-I5qVI4Y7k39_dB-LWUFm9pUafp9WEdW3do01ZcELGH96KQxU6742iu3PChEt8PjZ6kxRQImOu4XJA0pBdQPjihhVBGuZO82fYHcujjUTEbPJG92yHWHFlFP3PTJEyfYyH2Fu6qwO_kWn5lvlwQPq5vWY3Ca_gc64jVyqj6366-6Frt25ptYJ3s7PHq9FbTW65SNWWks0TqODJWXIaYoIZzDRY5oVtUrFSoWPZgWBfy_eW1_PsVIVQjOw',
  },

// This is a Carto BaseMap
  //  basemap: BASEMAP.VOYAGER,
  mapboxToken: 'pk.eyJ1IjoiY3liZXJjb25jZXB0cyIsImEiOiJja3NzMzZjb2MwZnJvMnFsYjRpeTU4OXQ0In0.YlVAx5c1I0uQIqsml27WYw',
  mapLoaded: false,
  viewState: initialViewState(),
  viewportFeatures: [],
// This doesn't seem to be the place to put it.
  // projection: {
  //   name: 'equalEarth'
  // }
}
