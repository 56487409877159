export const MODULE_NAME = 'map'

export const ACTIONS = {}

export const MUTATIONS = {
  SET_MAP_LOADED: 'setMapLoaded',
  SET_VIEWSTATE: 'setViewState',
  RESET_VIEWSTATE: 'resetViewstate',
  SET_VIEWPORT_FEATURES: 'setViewportFeatures'
}
